<template>
  <div class="content">
    <a-button
      type="primary"
      shape="circle"
      class="filter-btn"
      @click="onShowFilter"
    >
      <template #icon><FilterOutlined /></template>
    </a-button>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
        <div class="item" v-for="(v, k) in list" :key="k">
          <div class="top">
            <span>{{ v.date }}</span>
          </div>
          <a-row>
            <a-col :span="24">
              <e-info
                :labelCol="8"
                :valueCol="6"
                title="订单数量"
                :value="v.orderCount"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="16"
                :valueCol="6"
                title="订单总人数"
                :value="v.peopleCount"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="16"
                :valueCol="6"
                title="人均"
                :value="v.peopleAvgPayAmount"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="16"
                :valueCol="6"
                title="消费总额"
                :value="v.totalAmount"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="16"
                :valueCol="6"
                title="桌均"
                :value="v.tableAvgPayAmount"
              />
            </a-col>
          </a-row>
        </div>
      </van-list>
    </van-pull-refresh>

    <Footer />

    <FilterBox ref="filterBox" v-model:value="show">
      <div class="filter-item">
        <div class="item-title">开始日期</div>
        <Date
          title="开始日期"
          :showTitle="false"
          v-model:value="queryParams.BeginDate"
        />

        <div class="item-title">结束日期</div>
        <Date
          title="结束日期"
          :showTitle="false"
          v-model:value="queryParams.EndDate"
        />

        <div class="item-title">选择类型</div>
        <div class="item-form">
          <a-radio-group v-model:value="queryParams.Type">
            <a-radio v-for="(v, k) in options" :key="k" :value="v.value">{{
              v.label
            }}</a-radio>
          </a-radio-group>
        </div>

        <div class="btns">
          <div class="btn ok" @click="onOkFilter">确定</div>
          <div class="btn cancle" @click="onCloseFilter">取消</div>
          <div class="btn reset" @click="onResetFilter">重置</div>
        </div>
      </div>
    </FilterBox>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { FilterOutlined } from '@ant-design/icons-vue'
import Footer from './Footer'
import { List, PullRefresh } from 'vant'

import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import { OrderClass } from '@/apis/order'
import defaultConfig from '@/config/default.config'
import $store from '@/store'
import FilterBox from '@/components/vant-form/FilterBox'
import Date from '@/components/vant-form/Date'

const order = new OrderClass()
export default defineComponent({
  components: {
    Footer,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    FilterOutlined,
    FilterBox,
    Date
  },
  setup () {
    const queryParams = ref({
      Limit: 20,
      Type: 0,
      UserId: ''
    })
    const paginate = ref({
      total: 0,
      page: 1
    })
    const store = ref()
    const list = ref([])
    const show = ref(false)
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)
    const activeTab = ref(-1)

    const onLoad = () => {
      console.log('加载', paginate.value.total, paginate.value.page)
      if (
        paginate.value.page * queryParams.value.Limit >= paginate.value.total ||
        paginate.value.total <= queryParams.value.Limit
      ) {
        finished.value = true
      } else {
        getDataList(++paginate.value.page)
      }
    }

    const getDataList = (page) => {
      loading.value = true
      if (page === 1) {
        list.value = []
      }

      paginate.value.page = page
      const q = { ...queryParams.value }

      q.Offset = (page - 1) * q.Limit
      order.orderMoney(q).then((resp) => {
        list.value.push(...resp.data)

        paginate.value.total = resp.total

        loading.value = false
        refreshing.value = false

        if (page >= resp.total) {
          finished.value = true
        } else {
          finished.value = false
        }
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      getDataList(1)
    }

    const onType = (e) => {
      if (e.name === -1) {
        delete queryParams.value.OrderType
      } else {
        queryParams.value.OrderType = e.name
      }

      getDataList(1)
    }

    const onShowFilter = () => {
      show.value = true
    }

    const onCloseFilter = () => {
      show.value = false
    }

    const onOkFilter = () => {
      show.value = false
      getDataList(1)
    }

    getDataList(1)

    return {
      queryParams,
      paginate,
      list,
      loading,
      finished,
      refreshing,
      store,
      show,
      types: [
        {
          title: '全部',
          value: -1
        },
        {
          title: '预订',
          value: 0
        },
        {
          title: '开餐',
          value: 1
        },
        {
          title: '退订',
          value: 2
        },
        {
          title: '不来',
          value: 3
        },
        {
          title: '已结算',
          value: 4
        }
      ],
      activeTab,
      options: [
        { label: '日报', value: 0 },
        { label: '月报', value: 1 }
      ],
      operatorId: $store.state.user.userInfo.id,
      onLoad,
      onRefresh,
      onType,
      onShowFilter,
      onCloseFilter,
      onOkFilter
    }
  },

  created () {
    this.getStoreInfo()
  },
  methods: {
    async getStoreInfo () {
      const store = this.$store.state.user.storeInfo.id
        ? this.$store.state.user.storeInfo
        : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    },

    onResetFilter () {
      this.queryParams = {
        Limit: 20,
        Type: 0,
        UserId: ''
      }
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
}

.list {
  padding: 0.3rem;
  .item {
    border-radius: 0.1rem;
    box-shadow: 0 0 0.25rem rgba(150, 150, 150, 0.3);
    margin-bottom: 0.3rem;
    background: #ffffff;
    padding: 0.3rem 0.18rem;
    .top {
      font-size: 0.28rem;
      color: #333333;
      line-height: 0.44rem;
      display: flex;
      justify-content: space-between;
      .state {
        font-size: 0.24rem;
      }
    }

    .info-box {
      padding-top: 0.2rem;
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #ebebeb;
      position: relative;
      padding-left: 1.98rem;
      .img {
        width: 1.78rem;
        height: 1.15rem;
        border-radius: 0.08rem;
        overflow: hidden;
        position: absolute;
        top: 0.2rem;
        left: 0;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .info {
        font-size: 0.24rem;
        position: relative;
        height: 1.15rem;
        .name {
          line-height: 0.6rem;
          font-size: 0.3rem;
        }
        .time {
          line-height: 0.44rem;
        }
        .combo {
          position: absolute;
          bottom: 0;
          right: 0;
          line-height: 0.6rem;
          line-height: 0.4rem;
        }
      }
    }

    .price-action {
      padding-top: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        font-size: 0.36rem;
      }
      .remark {
        font-size: 0.24rem;
        width: 4rem;
      }
    }
  }
}
.filter-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
}
.filter-item {
  padding: 0.1rem;
  .item-title {
    margin-top: 20px;
  }
  .item-form{
    height: 0.85rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
  }

  .btns{
    padding-top: 20px;
    .btn{
      width: 100%;
      height: 0.7rem;
      text-align: center;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      margin-top: 10px;
      line-height: 0.7rem;
      &.ok{
        border-color: #1890ff;
        background: #1890ff;
        color: #fff;
      }
      &.cancle{
        border-color: #ff7875;
        background: #ff7875;
        color: #fff;
      }
      &.reset{
        border-color: #ff7875;
      }
    }
  }
}
</style>
