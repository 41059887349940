<template>
  <div class="footer">
    <router-link class="nav" v-for="(v, k) in navs" :key="k" :to="v.url">
      <div class="icon">
        <icon-font :type="v.icon" />
      </div>
      <div class="title">{{v.title}}</div>
    </router-link>

    <a @click.stop="logout" class="nav danger">
      <div class="icon">
        <logout-outlined />
      </div>
      <div class="title">退出</div>
    </a>
  </div>
</template>

<script>
import { defineComponent, createVNode } from 'vue'
import { LogoutOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import store from '@/store'
import router from '@/router'

export default defineComponent({
  components: {
    LogoutOutlined
  },
  props: {
    title: String
  },
  setup () {
    const logout = () => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '确定退出当前用户吗'
        ),
        okText: '退出',
        onOk () {
          store.dispatch('Logout').then(() => {
            message.success('已退出')
            router.push({ name: 'shareholder-login' })
            store.commit('SET_PORT', '')
          })
        }
      })
    }
    return {
      navs: [
        {
          title: '台位',
          icon: 'icon-yuyue',
          url: '/shareholder/tables'
        },
        {
          title: '订单',
          icon: 'icon-shenqingjilu',
          url: '/shareholder/orders'
        },
        {
          title: '统计',
          icon: 'icon-shujuzhanshi2',
          url: '/shareholder/stats'
        },
        {
          title: '客户',
          icon: 'icon-kehu',
          url: '/shareholder/customers'
        }
      ],
      logout
    }
  }
})
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  z-index: 2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  .nav {
    color: #333;
    font-size: 0.4rem;
    width: 50%;
    .icon{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.6rem;
    }
    .title{
      font-size: 0.2rem;
      line-height: 0.3rem;
      text-align: center;
    }
    &.router-link-active{
      color: #ceb175;
    }
    &.danger{
      color: #f50;
    }
  }
}
</style>
